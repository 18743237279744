'use client';
import {
  CssBaseline,
  createTheme,
  useMediaQuery,
  ThemeProvider,
} from '@mui/material';
import { useMemo } from "react";

function AppUI({ children }) {


  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          primary: {
            light: "#8251E2",
            main: "#682FBD",
            dark: "#9A72E7",
          },
          secondary: {
            light: "#8251E2",
            main: "#682FBD",
            dark: "#9A72E7",
          },
          appbar: {
            main: prefersDarkMode ? "#0D0D0D" : "#FBF9FF",
          },
          background: {
            appbar: prefersDarkMode ? "#0D0D0D" : "#FBF9FF",
            searchbar: prefersDarkMode ? "#0F0F0F" : "#E0E0E0",
            card: {
              background: prefersDarkMode ? "#222126" : "#FFFFFF",
              foreground: prefersDarkMode ? "#303030" : "#D8D8D8",
            },
            carousel: {
              background: prefersDarkMode ? "#0F0F0F" : "#E0E0E0",
            },
            paper: prefersDarkMode ? "#222126" : "#FFFFFF",
            default: prefersDarkMode ? "#050505" : "#FBF9FF",
          },
          text: {
            accent: prefersDarkMode ? "#B89CED" : "#682FBD",
            primary: prefersDarkMode ? "#FFFFFF" : "#000000",
            secondary: prefersDarkMode
              ? "rgba(255, 255, 255, 0.7)"
              : "rgba(0, 0, 0, 0.6)",
          },
          divider: prefersDarkMode ? "#2B2B2B" : "#E0E0E0",
        },
        typography: {
          fontFamily: [
            'Maven Pro, sans-serif',
            "DM Serif Text, serif"
          ].join(','),

          h1: {
            lineHeight: 1,
          },
          h2: {
            lineHeight: 1,
          },
          h3: {
            lineHeight: 1,
          },
          h4: {
            lineHeight: 1,
          },
          h5: {
            lineHeight: 1,
          },
          h6: {
            lineHeight: 1,
          },
          subtitle2: {
            lineHeight: 1,
          },
          subtitle1: {
            lineHeight: 1,
          },
          body2: {
            lineHeight: 1,
          },
          body1: {
            lineHeight: 1,
          },
          button: {
            textTransform: "none",
          },
          caption: {
            lineHeight: 1,
          },
          overline: {
            lineHeight: 1,
          },
        },
      }),
    [prefersDarkMode]
  );

  /* const theme = createTheme({
      
      palette: {
          primary: {
              main: "#5D2AD5",
          },
          secondary: {
              main: "#000",
          },
          background: {
              paper: "#FFFFFF",
              default: "#FAF8FF",
          },
          appbar: {
              main: "#FFFFFF",
          },
      },
      typography: {
          fontFamily: font.style.fontFamily,
      },
  }); */

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>);
}

export default AppUI;